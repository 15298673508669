<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <filter-interface
      v-if="!loading && permissions.read"
      :model="model"
      :nav="nav"
      :pageName="pageName"
      :rows="rows"
      :searchProperties="searchProperties"
      :staticDataProperties="staticDataProperties"
      :staticData="staticData"
      :loading="loading"
      :permissions="permissions"
      :sortDirectionProp="sortDirection"
      :sortPropertyProp="sortProperty"
      :sortPropertyOptionsProp="sortPropertyOptions"
    />
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import FilterInterface from '@/components/FilterInterface.vue'

export default {
  name: 'Taxonomy',
  components: {
    FilterInterface
  },
  computed: {
    rows: {
      get () {
        return this.$store.state.taxonomy
      },
      set (payload) {
        this.$store.commit('setTaxonomy', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.add = ac.can(this.user.acgroups).createAny('taxonomy').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      loading: true,
      model: 'taxonomy',
      nav: [
        { name: 'Taxonomy', text: 'Taxonomy' }
      ],
      pageName: 'Taxonomy',
      permissions: {
        add: false,
        read: true
      },
      searchProperties: ['name', 'description', 'id'],
      staticDataProperties: [
      ],
      sortDirection: 'desc',
      sortProperty: 'name',
      sortPropertyOptions: [
        { value: 'name', text: 'name' }
      ],
      staticData: null
    }
  },
  methods: {
    load: async function () {
      try {
        this.loading = true
        await this.loadRows()
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadRows: async function () {
      this.loading = true
      this.rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/taxonomy/limit/${limit}/offset/${offset}`)
          const data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
